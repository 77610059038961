<template>
  <!-- 服务协议管理 -->
  <div class="containerbox serverContent">
    <div class="formcontent">
      <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
        <el-form-item label="协议类型" prop="agreementType">
          <el-input v-model="formData.agreementType"></el-input>
        </el-form-item>
        <el-form-item label="协议内容" prop="agreementContent">
          <div id="eidtContent"></div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addServe">保存</el-button>
          <el-button @click="gobackPage()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
// import paging from "@/components/pagings.vue";
import E from "wangeditor";

export default {
  inject: ["reload"],

  data() {
    return {
      editor: {},
      formData: {},
      rules: {
        agreementType: [
          { required: true, message: "请输入协议类型", trigger: "blur" },
        ],
        agreementContent: [
          { required: true, message: "请输入协议内容", trigger: "blur" },
        ]
      },
      pageNum: 1, //页码
      size: 50, //一页几条,
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      tableData: [], //表格数据
      tableHeigth: 0,
      selectMemberData: [],
    };
  },
  components: {
    // paging,
  },
  updated() {},
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  mounted() {
    this.initE();
  },
  watch: {},
  methods: {
    //   获取详情内容
    getDetail() {
      let querydata = {};
      querydata.configAgreementId = this.$route.query.id;
      Api.configAgreementDetail(querydata).then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.result);
          this.formData = res.data.result;
          this.editor.txt.html(res.data.result.agreementContent);
        }
      });
    },
    gobackPage() {
      this.$router.go(-1);
    },
    //   初始化富文本编辑器
    initE() {
      this.editor = new E("#eidtContent");
      this.editor.config.height = 500;//富文本高度
      // 编辑器菜单栏配置
        this.editor.config.menus = [
          "head", // 标题
          "bold", // 粗体
          "fontSize", // 字号
          "indent", //缩进
          "fontName", // 字体
          "italic", // 斜体
          "underline", // 下划线
          "strikeThrough", // 删除线
          "foreColor", // 文字颜色
          "backColor", // 背景颜色
          "link", // 插入链接
          "list", // 列表
          "justify", // 对齐方式
          "quote", // 引用
          "emoticon", // 表情
          "table", // 表格
          "code", // 插入代码
          "undo", // 撤销
          "redo", // 重复
        ];
      
      this.editor.create();
    },

    addServe() {
      //新增协议 保存接口
      this.formData.agreementContent=this.editor.txt.html()
      let querydata = JSON.parse(JSON.stringify(this.formData));
      querydata.agreementContent = this.editor.txt.html();

      let sign = tools.getSign(querydata);
      querydata.sign = sign;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          //   判断是新增接口还是修改接口
          if (this.$route.query.id) {
            Api.updateConfigAgreement(querydata).then((res) => {
              if (res.data.status == "success") {
                this.$message.success("修改成功");
                this.getDetail(); //刷新
              }
            });
          } else {
            Api.addConfigAgreement(querydata).then((res) => {
              if (res.data.status == "success") {
                this.$message.success("新建成功");
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.formcontent {
  max-width: 800px;
}
.containerbox {
  // height: calc(100vh - 61px);
  height: 100vh;
  padding: 16px;
}

.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgbox {
  width: 100%;
  margin-left: 12%;
  box-sizing: border-box;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.serverContent {
  display: flex;
  justify-content: center;
  .tableBox {
    background-color: #fff;
  }
  .btnList1 {
    width: auto;
    padding: 10px;
    border-radius: 4px;
  }
}
</style>
